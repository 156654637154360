
import settingPages from "@/utils/settingPages.json";
import { PopoutsModule } from "@/store/modules/popouts";
import { DrawersModule } from "@/store/modules/drawers";

interface Pages {
  [key: string]: {
    name: string;
    icon: string;
    component: string;
    alert?: boolean;
  };
}

import { defineComponent } from "vue";
export default defineComponent({
  name: "MainApp",
  data() {
    return {
      pages: settingPages as any as Pages,
    };
  },
  computed: {
    currentSettingTab(): any {
      return this.$route.params.tab;
    },
  },
  methods: {
    changeTab(path: string) {
      DrawersModule.SetLeftDrawer(false);

      this.$router.push({ params: { tab: path } });
    },
    showMarkupGuide() {
      PopoutsModule.ShowPopout({
        id: "markup-guide",
        component: "markup-guide",
      });
    },
    showChangelog() {
      PopoutsModule.ShowPopout({
        id: "changelog-popout",
        component: "ChangelogPopout",
        data: {},
      });
    },
  },
});
